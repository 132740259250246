<template>
  <div class="registerModal">
    <div class="modalHeader">
      <div class="stepContainer">
        <div class="stepBlocks activeStep">
          <div class="stepNumber activeStep">1</div>
        </div>

        <div class="spacer"></div>

        <div class="stepBlocks">
          <div class="stepNumber">2</div>
        </div>

        <div class="spacer"></div>

        <div class="stepBlocks">
          <div class="stepNumber">3</div>
        </div>
      </div>
      <span>Welcome to sStream!</span>
      <span class="sub-header"
        >To get started, please tell us who you are.</span
      >
    </div>
    <div class="modalBody">
      <form class="register">
        <div class="inputs">
          <div class="input">
            <label>First Name <span class="error" v-for="error of v$.firstName.$errors" :key="error.$uid">{{ error.$message }}</span></label>
            <input
              type="text"
              placeholder="First Name"
              v-model="v$.firstName.$model"
              v-bind:class="{ error: v$.firstName.$error }"
              required
            />
          </div>
          <div class="input">
            <label>Last Name <span class="error" v-for="error of v$.lastName.$errors" :key="error.$uid">{{ error.$message }}</span></label>
            <input
              type="text"
              placeholder="Last Name"
              v-model="v$.lastName.$model"
              v-bind:class="{ error: v$.lastName.$error }"
              required
            />
          </div>
          <div class="input">
            <label>Email</label>
            <input
              type="email"
              placeholder="Email"
              v-model="email"
              required
              readonly
            />
          </div>
          <div class="input" >
            <label>Phone Number <div class="error"  v-for="error of v$.phoneNumber.$errors" :key="error.$uid"><span v-if="phoneErrorVisibility"  >{{ error.$message }}</span></div></label>

            <vue-tel-input
              v-model="v$.phoneNumber.$model"
              v-bind="options"
              @on-input="onInput"
            />
          </div>
        </div>
        <div class="message">
          <span class="header"> How does sStream already have this info? </span>
          <span>
            A Streamland Staff member has used your personal details in order to
            invite you. Streamland keeps access records, and your information is
            used to ensure media security.
          </span>
          <span class="link">Click here to learn more...</span>
        </div>
        <div class="footer">
          <button @click.prevent="nextStep">CONTINUE</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { VueTelInput } from "vue-tel-input";
import "../vue-tel-input/component.css";
import "../vue-tel-input/sprite.css";
import useVuelidate from "@vuelidate/core";
import { required, email, alpha } from "@vuelidate/validators";

export default {
  name: "Register",
  data() {
    return {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      userId: "",
      userRole: "",
      phoneObject: {
        formatted: "",
        valid: false,
        country: undefined,
      },
      phoneErrorVisibility: false,
      phoneErrorState: null,
      errorMsg: "",
      options: {
        mode: "international",
        autoFormat: true,
        defaultCountry: "US",
        placeholder: "Enter a phone number",
        required: true,
        enabledCountryCode: true,
        autocomplete: true,
        name: "telephone",
        maxLen: 25,
        phone: {},
        inputOptions: {
          styleClasses: "",
        },
        dropdownOptions: {
          showDialCodeInList: true,
          showFlags: true,
        },
      },
    }
  },
  emits: ["nextStep"],
  components: {
    VueTelInput,
  },
  props: {
    userInfo: Object,
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      firstName: { required, alpha },
      lastName: { required, alpha },
      phoneNumber: { required },
    };
  },
  created() {
    this.firstName = this.userInfo.firstName;
    this.lastName = this.userInfo.lastName;
    this.email = this.userInfo.email;
    this.phoneNumber = this.userInfo.phoneNumber;
    this.userId =  this.userInfo.userId;
    this.userRole =  this.userInfo.userRole;
  },
  methods: {

    onInput(formattedNumber, phoneObject) {
     let requiredError = this.v$.phoneNumber.required.$response
      this.phone = phoneObject;
      // console.log(this.phone.number);
        if (this.phoneNumber !== "") {
          this.phoneErrorVisibility  = true;
        }
        if (requiredError == false && this.phoneErrorVisibility == true) {
          this.options.inputOptions.styleClasses = "error";
        } else {
          this.options.inputOptions.styleClasses = "";
        }

    },

    async nextStep() {
        let nextStepData = {
          compName: "inviteRegistrationP3",
          firstName: this.firstName,
          lastName: this.lastName,
          phoneNumber: this.phone.number,
          email: this.email,
          userId: this.userId,
          userRole: this.userRole,
          };
          const isFormCorrect = await this.v$.$validate();
          // console.log(this.v$.phoneNumber.required.$response)
          if (this.v$.phoneNumber.required.$response == false) {
            this.options.inputOptions.styleClasses = "error"
            this.phoneErrorVisibility  = true;
          } else {
          this.options.inputOptions.styleClasses = "";
          this.phoneErrorVisibility  = false;
        }
                if (isFormCorrect == true) {
                  // console.log(isFormCorrect)
                  // console.log(nextStepData);

        this.$emit("nextStep", nextStepData);    
      }
          

    },
  },
}
</script>

<style lang="scss" scoped>
.registerModal {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #2f323c;
  z-index: 1001;
  width: 542px;
  margin-left: auto;
  flex-shrink: 0;
  color: #e5ecff;
  background-color: #2f323c;

  .modalHeader {
    background-color: #2f323c !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 30px;

    span {
      font-family: Roboto;
      font-weight: 300;
      font-size: 32px;
      color: #eff3ff;
    }

    .sub-header {
      font-size: 18px !important;
    }
    .stepContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 50%;
margin-bottom: 25px;

      .spacer {
        width: 50px;
        border-top: 3px dotted #444b5a;
      }

      .activeStep {
        border-color: #eff3ff !important;
        color: #eff3ff !important;
      }

      .completedStep {
        border-color: #eff3ff !important;
        color: #eff3ff !important;
      }
      .stepBlocks {
        background-color: inherit;
        border: #444b5a 2px solid;
        width: 35px;
        height: 35px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        .stepNumber {
          font-weight: 700;
          color: #444b5a;
          font-size: 25px;
          margin-right: auto;
          margin-left: auto;
        }
      }
    }
  }

  .modalBody {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;

    label {
     display: flex;
    flex-direction: row;
    align-items: flex-end;

      span {
        margin-left: 10px;
      }
    }

    input {
    max-height: 50px;
    min-height: 45px;
      background-color: #262830;
      border: 1px solid #17181a;
      margin: 10px 0px 15px 0px;
      color: #eff3ff;
      padding: 10px;
    }


      }
    div .input {
      display: flex;
      flex-direction: column;
    }

    .register {
      width: calc(100% - 80px);
      margin-bottom: 0px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
padding: 30px 40px;    }
  }

  .message {
    display: flex;
    flex-direction: column;
    background-color: #444b5a;
    padding: 10px;
    color: #eff3ff;
    align-items: center;
    justify-items: center;
    font-size: 14px !important;

    span {
      text-align: center;
      font-weight: 400;
    }

    .header {
      font-weight: 700;
      margin-bottom: 10px;
    }

    .link {
      margin-top: 10px;
      color: #b2c7ff;
    }
  }

  .footer {
    display: flex;
    align-items: flex-end;
    margin-bottom: 0px;
    height: auto;
    width: 100%;

    button {
      background-color: #434b5a;
      border: none;
      height: 60px;
      width: 460px;
      color: #EFF3FF;
      font-size: 18px;
      font-weight: 700;
      cursor: pointer;
    }

    button:hover {
      background-color: #0084FF;
    }
  }
.error {
  color: #ad0000 !important;
  font-size: 14px;
}

     input.error {
      background-color: #4d2121 !important;
      border: #ad0000 solid 1px !important;
      color: #ad0000 !important;
}
</style>