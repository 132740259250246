<template>
  <div class="inviteRegistration">
    <component :userInfo='userInfo' :is="registrationStage" @nextStep="onNextStep($event)" />
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import {
  timestamp,
  auth,
  rtdb,
  db,
} from "../components/firebase/firebaseInit.js";
import { v4 as uuidv4 } from "uuid";
import inviteRegistrationP1 from "../components/inviteRegistrationFlow/inviteRegistrationP1.vue";
import inviteRegistrationP2 from "../components/inviteRegistrationFlow/inviteRegistrationP2.vue";
import inviteRegistrationP3 from "../components/inviteRegistrationFlow/inviteRegistrationP3.vue";
import inviteRegistrationP4 from "../components/inviteRegistrationFlow/inviteRegistrationP4.vue";
import inviteRegistrationP5 from "../components/inviteRegistrationFlow/inviteRegistrationP5.vue";

export default {
  name: "Register",
  data() {
    return {
      userInfo: {
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: null,
        password: "",
        acceptableUseageAgreement: false,
        privacyPolicyAgreement: false,
        cookieAgreement: false,
        userRole: "",
        source: "",
        userId: "",
      },
      error: "",
      errorMsg: "",
      errors: null,
      registerComplete: false,
      registrationStage: inviteRegistrationP1,
    };
  },
  components: {
    inviteRegistrationP1,
    inviteRegistrationP2,
    inviteRegistrationP3,
    inviteRegistrationP4,
    inviteRegistrationP5
  },
  created() {
            if (this.$store.state.deviceId == null) {
              this.$store.state.deviceId = uuidv4();
            }
  },
  methods: {
    onNextStep(nextStepData) {
    this.userInfo.firstName = nextStepData.firstName;
    this.userInfo.lastName = nextStepData.lastName;
    this.userInfo.email = nextStepData.email;
    this.userInfo.phoneNumber = nextStepData.phoneNumber;
    this.userInfo.password = nextStepData.password;
    this.userInfo.acceptableUseageAgreement = nextStepData.acceptableUseageAgreement;
    this.userInfo.privacyPolicyAgreement = nextStepData.privacyPolicyAgreement;
    this.userInfo.cookieAgreement = nextStepData.cookieAgreement;
    this.registrationStage = nextStepData.compName;
    this.userInfo.userId = nextStepData.userId;
    this.userInfo.userRole = nextStepData.userRole;
    },
  },
};
</script>

<style lang="scss" scoped>
.register {
  h2 {
    max-width: 350px;
  }
}
.inviteRegistration {
  display: flex;
  justify-items: center;
  align-items: center;
  background-image: url("../assets/loginbackground.jpg");
  width: 100vw;
  height: 100vh;
}
</style>

