<template>
  <div class="registerModal">
    <div class="modalHeader">
      <span>Welcome to sStream!</span>
      <span class="sub-header"
        >We'll walk you through setting up your account.</span
      >
    </div>
    <div class="modalBody">
      <div class="stepBlocks">
        <div class="stepNumber">1</div>
        <div class="stepText">Set up your profile.</div>
      </div>

      <div class="spacer"></div>

      <div class="stepBlocks">
        <div class="stepNumber">2</div>
        <div class="stepText">Set your password.</div>
      </div>

      <div class="spacer"></div>

      <div class="stepBlocks">
        <div class="stepNumber">3</div>
        <div class="stepText">Accept our policies.</div>
      </div>
    </div>
    <div class="footer">
      <button @click="nextStep">GET STARTED.</button>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import {
  timestamp,
  auth,
  rtdb,
  db,
} from "../firebase/firebaseInit.js";
export default {
  data() {
    return {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: null,
      source: "",
      userId: "",
      userRole: "",
      errorMsg: "",
      error: false,
    };
  },
  emits: ["nextStep"],
  props: {
    userInfo: Object,
  },
  methods: {
    nextStep() {
      let nextStepData = {
        compName: "inviteRegistrationP2",
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        phoneNumber: this.phoneNumber,
        source: this.source,
        userId: this.userId,
        userRole: this.userRole,
      };
      this.$emit("nextStep", nextStepData);
      console.log(nextStepData);
    },
  },
  async created() {
    this.id = this.$route.query.id;

    await db
      .collection("mail")
      .doc(this.id)
      .get()
      .then((doc) => {
        //check if registration invite exists and get data for registration
        if (doc.exists) {
          //check if registration invite has expired
          if (doc.data().expired == true) {
            this.$router.push("/");
            //if registration invite has not expired get data and get user doc to populate form
          } else {
            // console.log("Document data:", doc.data());
            this.email = doc.data().to;
            this.source = doc.data().source;
            this.userId = doc.data().userId;

            db.collection("users")
              .doc(doc.data().userId)
              .get()
              .then((doc) => {
                if (doc.exists) {
                  this.firstName = doc.data().firstName;
                  this.lastName = doc.data().lastName;
                  this.phoneNumber = doc.data().phoneNumber;
                  this.userRole = doc.data().userRole.toLowerCase();
                  console.log("No such document!");
                } else {
                  console.log("No such document!");
                }
              });
          }
          //if registration invite does not exist
        } else {
          this.$router.push("/");
          console.log("No such document!");
        }
      }).catch((e)=>{
        console.log(e)
        this.$router.push("/");
      })
  },
  mounted() {
    // console.log(this.userInfo);
  },
};
</script>


<style lang="scss" scoped>
.registerModal {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #2f323c;
  z-index: 1001;
  width: 542px;
  margin-left: auto;
  flex-shrink: 0;
  color: #e5ecff;
  background-color: #2f323c;

  .modalHeader {
    background-color: #2f323c !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 140px;

    span {
      font-family: Roboto;
      font-weight: 300;
      font-size: 32px;
      color: #eff3ff;
    }
    .sub-header {
      font-size: 18px !important;
    }
  }

  .modalBody {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;

    .spacer {
      height: 50px;
      border-right: 4px dotted #444b5a;
    }

    .stepBlocks {
      background-color: #444b5a;
      width: 55%;
      height: 80px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;

      .stepNumber {
        font-weight: 700;
        color: #262830;
        font-size: 36px;
        margin-right: auto;
        margin-left: auto;
      }

      .stepText {
        font-weight: 700;
        color: #eff3ff;
        font-size: 18px;
        margin-right: auto;
      }
    }
  }
  .footer {
    margin-bottom: 50px;

    button {
      background-color: #434b5a;
      border: none;
      height: 60px;
      width: 460px;
      color: #eff3ff;
      font-size: 18px;
      font-weight: 700;
      cursor: pointer;
    }

    button:hover {
      background-color: #0084ff;
    }
  }
}
</style>