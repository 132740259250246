<template>
  <loading v-if="processing" />
  <div class="registerModal">
    <div class="modalHeader">
      <div class="stepContainer">
        <div class="stepBlocks">
          <div class="stepNumber">
            <font-awesome-icon :icon="['fas', 'check']" />
          </div>
        </div>

        <div class="spacer"></div>

        <div class="stepBlocks">
          <div class="stepNumber">
            <font-awesome-icon :icon="['fas', 'check']" />
          </div>
        </div>

        <div class="spacer"></div>

        <div class="stepBlocks activeStep">
          <div class="stepNumber activeStep">3</div>
        </div>
      </div>
      <span>We need your consent.</span>
      <span class="sub-header"
        >Please confirm you agree to the details below.</span
      >
    </div>
    <div class="modalBody">
      <form class="register">
        <div class="inputs">
          <div class="input">
            <div class="policy">
              <input
                class="checkbox"
                v-model="v$.acceptableUseageAgreement.$model"
                v-bind:class="{ error: v$.acceptableUseageAgreement.$error }"
                type="checkbox"
              />

              <div class="policyText">
                I accept the <a>acceptable usage policy </a> and agree that I
                will not abuse sStream.
              </div>
            </div>
          </div>

          <div class="input">
            <div class="policy">
              <input
                class="checkbox"
                v-model="v$.privacyPolicyAgreement.$model"
                v-bind:class="{ error: v$.privacyPolicyAgreement.$error }"
                type="checkbox"
              />

              <div class="policyText">
                I accept the <a>privacy policy</a> & <a>terms</a>. Streamland
                Media may contact me by email or via text message.
              </div>
            </div>
          </div>

          <div class="input">
            <div class="policy">
              <input
                class="checkbox"
                v-model="v$.cookieAgreement.$model"
                v-bind:class="{ error: v$.cookieAgreement.$error }"
                type="checkbox"
                checked="true"
              />

              <div class="policyText">
                I permit Streamland Media to track the devices I use to access
                sStream, to ensure my account remains secure. Streamland Media
                may store <a>cookies</a>
                on my devices.
              </div>
            </div>
          </div>
        </div>

        <div class="footer">
          <button @click.prevent="register">SUBMIT</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/functions";
import {
  timestamp,
  auth,
  rtdb,
  db,
} from "../firebase/firebaseInit.js";import useVuelidate from "@vuelidate/core";
import { sameAs } from "@vuelidate/validators";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { DateTime } from "luxon";
import loading from "../Loading.vue";



library.add(fas);

export default {
  name: "Register",
  data() {
    return {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      password: "",
      userId: "",
      userRole: "",
      admin: false,
      acceptableUseageAgreement: null,
      privacyPolicyAgreement: null,
      cookieAgreement: null,
      registrationDate: null,
      errorMsg: "",
      error: false,
      processing: false
    };
  },
  components: {
    FontAwesomeIcon,
    loading
  },
  props: {
    userInfo: Object,
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      acceptableUseageAgreement: { sameAs: sameAs(true) },
      privacyPolicyAgreement: { sameAs: sameAs(true) },
      cookieAgreement: { sameAs: sameAs(true) },
    };
  },
  methods: {
    logIn() {
        // firebase.auth().onAuthStateChanged(function(user) {
        //  user.sendEmailVerification(); 
        // });
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          firebase.auth().currentUser.getIdTokenResult()
         .then(async ({claims}) => {
         this.$store.commit("userClaims", claims);
         })
          this.$store.dispatch("getCurentUser").then(() => {
            this.nextStep();
          });
          this.error = false;
          this.errorMsg = "";
        })
        .catch((err) => {
          this.error = true;
          this.errorMsg = err.message;
        });
    },
    timestamp() {
      const now = DateTime.now();
      const utc = now.setZone("UTC");
      // console.log(utc.toISO());
      // console.log(utc.toLocaleString(DateTime.DATETIME_FULL));
      this.registrationDate = utc.toISO();
    },
    async register() {
      console.log("test")
      const addMessage = firebase.functions().httpsCallable("CreateUser");
      const isFormCorrect = await this.v$.$validate();

console.log(this.userId)
console.log(this.$store.state.deviceId)
              db.collection("users")
                .doc(this.userId)
                .collection("approvedDevices")
                .doc(this.$store.state.deviceId)
                .set({
                  deviceId: this.$store.state.deviceId,
                  userId: this.userId
                })
                .then(() => {
                  console.log("Document successfully written!");
                })
                .catch((error) => {
                  console.error("Error writing document: ", error);
                });

      this.timestamp();

      var data = {
        email: this.email,
        password: this.password,
        phoneNumber: this.phoneNumber,
        admin: this.admin,
        firstName: this.firstName,
        lastName: this.lastName,
        acceptableUseageAgreement: this.acceptableUseageAgreement,
        privacyPolicyAgreement: this.privacyPolicyAgreement,
        cookieAgreement: this.cookieAgreement,
        registrationDate: this.registrationDate,
        userId: this.userId,
        userRole: this.userRole,
      };

      // console.log(data);

      if (isFormCorrect == true) {
        this.processing = true;
        this.error = false;
        this.errorMsg = "";
        addMessage(data)
          .then((result) => {
            db.collection("mail").doc(this.id).set(
              {
                expired: true,
              },
              { merge: true }
            );
            this.processing = false;
            console.log(result);
            this.logIn();
          })
          .catch((error) => {
            console.log(error);
          });
        // .then(function (result) {
        //   console.log(result);
        // })
        // .catch(function (error) {
        //   console.log(error);
        // });
        // this.nextStep();
        return;
      }
      this.error = true;
      this.errorMsg = "Please agree to all terms before submitting!";
      return;
    },

    nextStep() {
      let nextStepData = {
        compName: "inviteRegistrationP5",
        firstName: this.firstName,
        lastName: this.lastName,
        phoneNumber: this.phoneNumber,
        email: this.email,
        password: this.password,
        userId: this.userId,
        userRole: this.userRole,
        acceptableUseageAgreement: this.acceptableUseageAgreement,
        privacyPolicyAgreement: this.privacyPolicyAgreement,
        cookieAgreement: this.cookieAgreement,
      };
      this.$emit("nextStep", nextStepData);
      // console.log(nextStepData);
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.firstName = this.userInfo.firstName;
    this.lastName = this.userInfo.lastName;
    this.email = this.userInfo.email;
    this.phoneNumber = this.userInfo.phoneNumber;
    this.password = this.userInfo.password;
    this.userId = this.userInfo.userId;
    this.userRole = this.userInfo.userRole;
    this.admin = this.userInfo.userRole == "admin";
    this.timestamp();
    // console.log(this.admin);
  },
};
</script>

<style lang="scss" scoped>
.registerModal {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #2f323c;
  z-index: 1001;
  width: 542px;
  margin-left: auto;
  flex-shrink: 0;
  color: #e5ecff;
  background-color: #2f323c;

  .modalHeader {
    background-color: #2f323c !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 50px;

    span {
      font-family: Roboto;
      font-weight: 300;
      font-size: 32px;
      color: #eff3ff;
    }

    .sub-header {
      font-size: 18px !important;
    }
    .stepContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 50%;
      margin-bottom: 51px;

      .spacer {
        width: 50px;
        border-top: 3px dotted #444b5a;
      }

      .activeStep {
        border-color: #eff3ff !important;
        color: #eff3ff !important;
      }

      .completedStep {
        border-color: #eff3ff !important;
        color: #eff3ff !important;
      }
      .stepBlocks {
        background-color: inherit;
        border: #444b5a 2px solid;
        width: 35px;
        height: 35px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        .stepNumber {
          font-weight: 700;
          color: #444b5a;
          font-size: 25px;
          margin-right: auto;
          margin-left: auto;
        }
      }
    }
  }

  .modalBody {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;

    .policy {
      background-color: #444b5a;
      padding: 25px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .policyText {
      }

      a {
        color: #b2c7ff;
        cursor: pointer;
      }
    }

    .inputs {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 70%;
    }
    input {
      height: 60px;
      background-color: #262830;
      border: 5px solid #eff3ff;
      border-radius: 10px;
      color: #eff3ff;
      padding: 10px;
      width: 90%;
      margin-right: 20px;
      flex-shrink: 0;
    }

    $checkbox-size: 4rem;

    input[type="checkbox"].checkbox {
      appearance: none;
      width: $checkbox-size;
      height: $checkbox-size;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        color: rgb(178, 199, 255, 0);
        background-color: #383c49;
        border-radius: 10px;
        font: {
          size: 0rem;
          weight: bolder;
        }
        line-height: $checkbox-size;
        text-align: center;
        transition: background-color 350ms 0ms ease-out, color 50ms 50ms ease-in,
          font-size 75ms 150ms ease-out;
      }

      &:checked::after {
        content: "\02714";
        color: #b2c7ff;
        font-size: 3rem;
      }
    }

    div .input {
      display: flex;
      flex-direction: column;
    }

    .register {
      width: calc(100% - 80px);
      margin-bottom: 0px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding: 40px;
    }
  }

  .message {
    display: flex;
    flex-direction: column;
    background-color: #444b5a;
    padding: 10px;
    color: #eff3ff;
    align-items: center;
    justify-items: space-around;
    font-size: 18px;

    span {
      text-align: center;
      font-weight: 400;
    }

    .header {
      margin-top: 10px;
      font-weight: 700;
      margin-bottom: 10px;
    }

    .link {
      margin-top: 10px;
      color: #b2c7ff;
    }

    ul {
      list-style-type: none;
      width: 80%;
      padding: 0;
    }

    li {
      display: flex;
      justify-content: space-between;
      line-height: 1.5em;
    }

    .iconError {
      color: #e93c2d;
    }
  }

  .footer {
    display: flex;
    align-items: flex-end;
    margin-bottom: 0px;
    height: auto;
    width: 100%;

    button {
      background-color: #434b5a;
      border: none;
      height: 60px;
      width: 460px;
      color: #eff3ff;
      font-size: 18px;
      font-weight: 700;
      cursor: pointer;
    }

    button:hover {
      background-color: #0084ff;
    }
  }
  .checkbox.error {
    border: #ad0000 solid 4px !important;
  }
}
</style>